import React, { createContext, useState } from 'react';

export interface IFiles {
  [key: string]: {
    progress: number;
    downloadURL: string;
  };
}

type SetFilesStateType = React.Dispatch<
  React.SetStateAction<IFiles | IFiles[] | undefined>
>;

export const FilesContext = createContext<{
  files: IFiles | IFiles[] | undefined;
  setFiles: SetFilesStateType;
}>({ files: undefined, setFiles: () => null });

export default function FilesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [files, setFiles] = useState<IFiles | IFiles[]>();

  return (
    <FilesContext.Provider value={{ files, setFiles }}>
      {children}
    </FilesContext.Provider>
  );
}
