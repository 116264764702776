import { ChakraProvider } from '@chakra-ui/react';
import Head from 'next/head';
import AuthProvider from 'contexts/AuthContext';
import { theme } from 'styles/theme';
import 'styles/globals.scss';
import FormProvider, {
  defaultValues,
  IFormUser,
  userSchema,
} from 'contexts/FormContext';
import { useForm, FormProvider as HookFormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ActiveUserProvider from 'contexts/ActiveUserContext';
import FilesProvider from 'contexts/FilesContext';
import type { AppProps } from 'next/app';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (
  typeof window !== 'undefined' &&
  typeof process.env.NEXT_PUBLIC_POSTHOG_KEY === 'string'
) {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
    },
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  const formMethods = useForm<IFormUser>({
    resolver: yupResolver(userSchema),
    defaultValues,
  });

  return (
    <PostHogProvider client={posthog}>
      <ChakraProvider resetCSS theme={theme}>
        <AuthProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Head>
          <ActiveUserProvider>
            <FilesProvider>
              <HookFormProvider {...formMethods}>
                <FormProvider>
                  <Component {...pageProps} />
                </FormProvider>
              </HookFormProvider>
            </FilesProvider>
          </ActiveUserProvider>
        </AuthProvider>
      </ChakraProvider>
    </PostHogProvider>
  );
}

export default MyApp;
