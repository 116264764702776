import React, { createContext, useState } from 'react';
import { IUser } from 'types/User';

type SetActiveUserState = React.Dispatch<
  React.SetStateAction<IUser | undefined>
>;

type SetUsersState = React.Dispatch<React.SetStateAction<IUser[]>>;

export const ActiveUserContext = createContext<{
  activeUser: IUser | undefined;
  setActiveUser: SetActiveUserState;
  users: IUser[];
  setUsers: SetUsersState;
}>({
  activeUser: undefined,
  setActiveUser: () => null,
  users: [],
  setUsers: () => null,
});

export default function ActiveUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeUser, setActiveUser] = useState<IUser>();
  const [users, setUsers] = useState<IUser[]>([]);

  return (
    <ActiveUserContext.Provider
      value={{ activeUser, setActiveUser, users, setUsers }}
    >
      {children}
    </ActiveUserContext.Provider>
  );
}
