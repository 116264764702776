import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
  styles: {
    global: {
      html: {
        height: '100%',
      },
      body: {
        bg: 'gray.100',
        minHeight: '100%',
      },
      button: {
        letterSpacing: 'wide',
      },
      input: {
        letterSpacing: 'wide',
      },
      table: {
        tableLayout: 'fixed',
      },
    },
  },
});
