import React, { createContext, useState } from 'react';
import * as yup from 'yup';
import useUsers from 'hooks/useUsers';
import { IUser } from 'types/User';

export interface IFormUser {
  fullName: string;
  displayName?: string;
  birthDate: string;
  cpf: string;
  gender?: string;
  race?: string;
  children: string;
  cellphone: string;
  personalEmail: string;
  email?: string;
  address: IFormAddress;
  social?: IFormSocial;
  active: string;
  employee?: string;
  volunteerTerm: string;
  team: string;
  entryDate: string;
  incompleteEntryDate?: boolean;
  how?: string;
}

export interface IFormAddress {
  zipcode: string;
  street: string;
  number?: string;
  neighborhood: string;
  city: string;
  state: string;
  complementary?: string;
  loading?: boolean;
}

export interface IFormSocial {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
}

export const defaultValues = {
  fullName: '',
  displayName: '',
  birthDate: '',
  cpf: '',
  gender: '',
  race: '',
  children: 'false',
  cellphone: '',
  personalEmail: '',
  email: '',
  address: {
    zipcode: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    complementary: '',
  },
  social: {
    facebook: '',
    instagram: '',
    linkedin: '',
  },
  active: 'true',
  employee: 'false',
  volunteerTerm: 'false',
  team: '',
  entryDate: '',
  incompleteEntryDate: false,
  how: '',
};

interface IFormContext {
  userPersonalEmailExists: (personalEmail: string) => Promise<{
    isPersonalEmailAlreadyExists: boolean;
    user: IUser;
  }>;
  userEmailExists: (email: string) => Promise<{
    isEmailAlreadyExists: boolean;
    user: IUser;
  }>;
  userCpfExists: (cpf: string) => Promise<{
    isCpfAlreadyExists: boolean;
    user: IUser;
  }>;
  personalEmailExists: boolean;
  setPersonalEmailExists: React.Dispatch<React.SetStateAction<boolean>>;
  emailExists: boolean;
  setEmailExists: React.Dispatch<React.SetStateAction<boolean>>;
  cpfExists: boolean;
  setCpfExists: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormContext = createContext<IFormContext>({
  userPersonalEmailExists: personalEmail => new Promise(() => {}),
  userEmailExists: email => new Promise(() => {}),
  userCpfExists: cpf => new Promise(() => {}),
  personalEmailExists: false,
  setPersonalEmailExists: () => null,
  emailExists: false,
  setEmailExists: () => null,
  cpfExists: false,
  setCpfExists: () => null,
});

export const userSchema = yup.object().shape({
  fullName: yup.string().required('Informe o nome completo.'),
  birthDate: yup.string().required('Informe a data de nascimento.'),
  cpf: yup
    .string()
    .required('Informe o CPF.')
    .length(14, 'Parece que está faltando algum dígito.'),
  children: yup.string().required('É necessário responder essa pergunta.'),
  cellphone: yup
    .string()
    .length(15, 'Parece que está faltando algum dígito.')
    .required('Informe o número do celular.'),
  personalEmail: yup
    .string()
    .required('Informe o endereço de e-mail.')
    .email('O e-mail informado é inválido.'),
  address: yup.object().shape({
    zipcode: yup
      .string()
      .length(9, 'Parece que está faltando algum dígito.')
      .required('Informe o CEP.'),
    street: yup.string().required('Informe o logradouro (Rua, Avenida, etc).'),
    neighborhood: yup.string().required('Informe o bairro.'),
    city: yup.string().required('Informe a cidade.'),
    state: yup.string().required('Informe o estado.'),
  }),
  team: yup.string().required('Informe qual é o time.'),
  incompleteEntryDate: yup.bool(),
  entryDate: yup
    .string()
    .trim()
    .when('incompleteEntryDate', (incompleteEntryDate, schema) =>
      incompleteEntryDate
        ? schema
            .length(7, 'Parece que está faltando algum dígito.')
            .required('Informe a data de entrada.')
        : schema
            .length(10, 'Parece que está faltando algum dígito.')
            .required('Informe a data de entrada.')
    ),
});

export default function FormProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { userPersonalEmailExists, userEmailExists, userCpfExists } =
    useUsers();
  const [personalEmailExists, setPersonalEmailExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [cpfExists, setCpfExists] = useState(false);

  return (
    <FormContext.Provider
      value={{
        userPersonalEmailExists,
        userEmailExists,
        userCpfExists,
        personalEmailExists,
        setPersonalEmailExists,
        emailExists,
        setEmailExists,
        cpfExists,
        setCpfExists,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}
