import {
  collection,
  query,
  where,
  getDocs,
  DocumentData,
} from 'firebase/firestore';
import { db } from 'services/firebase';

export default function useUsers() {
  async function userPersonalEmailExists(personalEmail: string) {
    const personalEmailFilter = query(
      collection(db, 'users'),
      where('personalEmail', '==', personalEmail)
    );

    let querySnapshot: DocumentData = { docs: [] };
    try {
      querySnapshot = await getDocs(personalEmailFilter);
    } catch (error) {
      console.error(error);
    }

    const isPersonalEmailAlreadyExists = !!querySnapshot.docs.length;

    return {
      isPersonalEmailAlreadyExists,
      user: isPersonalEmailAlreadyExists ? querySnapshot.docs[0].data() : null,
    };
  }

  async function userEmailExists(email: string) {
    const emailFilter = query(
      collection(db, 'users'),
      where('email', '==', email)
    );

    let querySnapshot: DocumentData = { docs: [] };
    try {
      querySnapshot = await getDocs(emailFilter);
    } catch (error) {
      console.error(error);
    }

    const isEmailAlreadyExists = !!querySnapshot.docs.length;

    return {
      isEmailAlreadyExists,
      user: isEmailAlreadyExists ? querySnapshot.docs[0].data() : null,
    };
  }

  async function userCpfExists(cpf: string) {
    const cpfFilter = query(collection(db, 'users'), where('cpf', '==', cpf));

    let querySnapshot: DocumentData = { docs: [] };
    try {
      querySnapshot = await getDocs(cpfFilter);
    } catch (error) {
      console.error(error);
    }

    const isCpfAlreadyExists = !!querySnapshot.docs.length;

    return {
      isCpfAlreadyExists,
      user: isCpfAlreadyExists ? querySnapshot.docs[0].data() : null,
    };
  }

  return { userPersonalEmailExists, userEmailExists, userCpfExists };
}
