import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const userStructure = {
  fullName: '',
  displayName: '',
  address: {
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    zipcode: '',
    complementary: '',
  },
  document: { rg: '', cpf: '' },
  birthDate: '',
  phone: '',
  cellphone: '',
  email: '',
  personalEmail: '',
  gender: '',
  race: '',
  entryDate: {
    day: '',
    month: '',
    year: '',
  },
  hasSignedVolunteerTerm: false,
  hasChildren: false,
  isEmployee: false,
  team: '',
  active: false,
  social: {
    instagram: '',
    facebook: '',
    linkedin: '',
  },
  createdAt: Timestamp.fromDate(new Date(Date.now())),
  updatedAt: Timestamp.fromDate(new Date(Date.now())),
};

export const users = [{ ...userStructure }];
